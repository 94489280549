import { isEmpty, isNull } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Page } from "../../shared";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { s3Client } from "../../services/serviceS3";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
  getUserList,
  createSubmitAcceptTaskScheduler,
  createAcceptTaskScheduler,
  getTaskListDetail,
} from "../../services";
import "./style.css";
import ModalUnaccept from "./components/Modal/ModalUnaccept";

function TaskAccept(props) {
  const { className, userData } = props;
  const params = useParams();
  const [isLoadingCreating, setIsLoadingCreating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const [isLoadingUnaccept, setIsLoadingUnaccept] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  const [stateOptionsContact, setStateOptionsContact] = useState([]);
  const [isAccepted, setIsAccepted] = useState(false);
  const [showModalUnaccept, setShowModalUnaccept] = useState(false);
  const [arrFiles, setArrFiles] = useState([]);
  const [notes, setNotes] = useState(false);
  const [detail, setDetail] = useState([]);
  const [initialValues, setInitialValuesCreating] = useState({
    construction_link: "",
    additional_notes: "",
    hours_to_complete: "",
  });
  const [errTaskDetail, setErrTaskDetail] = useState(null);

  const handleAttachFile = async (e) => {
    try {
      setIsUploading(true);
      const files = e.target.files;
      const res = await s3Client.uploadFile(files[0]);
      setArrFiles(res.location);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };


  const handleGetDetailTask = async () => {
    try {
      const id = params.id;
      setIsLoading(true);
      setErrTaskDetail(null)
      const res = await getTaskListDetail(id);
      setDetail(res);
    } catch (e) {
      setErrTaskDetail('Something went wrong. Failed to loaded task detail. Please try again')
    } finally {
      setIsLoadingAccept(false);
      setIsLoading(false);
    }
  };

  const fetchContact = async () => {
    setLoadingContact(true);
    try {
      const res = await getUserList();
      if (res) {
        setStateOptionsContact(
          res?.results?.map((val) => ({
            label: `${val.first_name} - ${val.last_name} - (${val.email})`,
            value: `${val.id}`,
          }))
        );
        setLoadingContact(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchContact();
    handleGetDetailTask();
  }, []);

  const handleAcceptTask = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingAccept(true);
      const taskId = params.id;
      const payload = {
        task_id: taskId,
        accept: true,
      };
      const res = await createAcceptTaskScheduler(payload);
      if (res) {
        toast.success("Task Scheduler successfully accept", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setIsAccepted(true);
      }
    } catch (error) {
      toast.error(`Something went wrong. ${error?.response?.data?.message}`, {
        position: "bottom-right",
        autoClose: 2000,
      });
    } finally {
      setArrFiles(null)
      setIsLoadingAccept(false);
      handleGetDetailTask();
    }
  };

  const handleUnacceptTask = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingUnaccept(true);
      const taskId = params.id;
      const payload = {
        task_id: taskId,
        accept: false,
        notes,
      };
      const res = await createAcceptTaskScheduler(payload);
      if (res) {
        toast.success("Task Scheduler successfully unaccept", {
          position: "bottom-right",
          autoClose: 2000,
        });
        // setIsAccepted(true);
      }
    } catch (error) {
      toast.error(`Something went wrong. ${error?.response?.data?.message}`, {
        position: "bottom-right",
        autoClose: 2000,
      });
    } finally {
      setIsLoadingUnaccept(false);
      handleGetDetailTask();
      setShowModalUnaccept(false)
    }
  };

  const handleSubmitCreate = async (values) => {
    try {
      setIsLoadingCreating(true);
      const payload = {
        ...values,
        document_url: arrFiles,
        fk_task: parseInt(params.id, 10),
      };
      let res;
      res = await createSubmitAcceptTaskScheduler(payload);
      if (res) {
        setIsSubmitted(true);
        toast.success("Task Scheduler Accepted successfully submit", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setInterval(() => {
          window.location.replace("/task-scheduler/list");
        }, 2000);
      }
    } catch (error) {
      toast.error(`Something went wrong. ${error?.response?.data?.message}`, {
        position: "bottom-right",
        autoClose: 2000,
      });
    } finally {
      setIsLoadingCreating(false);
    }
  };

  const handleCancelDocuments = (imgUrl) => {
    setArrFiles(null);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmitCreate,
    enableReinitialize: true,
  });
  const { setFieldValue, handleSubmit } = formik;

  return (
    <>
      <Page
        title={`Task #${isEmpty(detail?.task_number) ? "-" : detail?.task_number
          }`}
      >
        <div className={className}>
          <hr />
          <div className="row d-flex">
            {isLoading && (
              <div className="text-center m-auto">
                <Spinner variant="primary" animation="border" />
              </div>
            )}
          </div>
          {
            errTaskDetail && (
              <div className="row">
                <div className="col-sm-6">
                  <p className="mt-2 font-weight-bold text-danger">{errTaskDetail}</p>
                </div>
              </div>
            )
          }

          <div className="row">
            <div className="col-sm-12">
              {detail?.status === "rejected" && (
                <div class="alert alert-danger" role="alert">
                  Task is <b>rejected</b>, please resubmit the task again
                </div>
              )}
              {detail?.status === "accepted" && (
                <div class="alert alert-success" role="alert">
                  Task is <b>accepted</b>, please submit the task
                </div>
              )}
            </div>
            <div className="col-sm-6">
              <form onSubmit={handleAcceptTask}>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>Search By Job #</label>
                    <input
                      readOnly
                      disabled
                      value={`${detail?.fk_vista_job?.vista_id} ${detail?.fk_vista_job?.description} `}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>Level/Area</label>
                    <input
                      readOnly
                      disabled
                      value={`${isEmpty(detail?.level_area) ? "" : detail?.level_area
                        }`}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>Stamp</label>
                    <input
                      readOnly
                      disabled
                      value={`${isEmpty(detail?.stamp) ? "" : detail?.stamp}`}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>Trade</label>
                    <input
                      readOnly
                      disabled
                      value={`${isEmpty(detail?.trade) ? "" : detail?.trade}`}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>Created By</label>
                    <input
                      readOnly
                      disabled
                      value={`${isEmpty(detail?.created_by?.fullname)
                        ? ""
                        : detail?.created_by?.fullname
                        }`}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>VDC Project Manager</label>
                    <input
                      readOnly
                      disabled
                      value={`${isEmpty(detail?.vdc_project_manager?.fullname)
                        ? ""
                        : detail?.vdc_project_manager?.fullname
                        }`}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>Due Date</label>
                    <input
                      readOnly
                      disabled
                      value={`${isEmpty(detail?.due_date)
                        ? ""
                        : moment(detail?.due_date).format("MM/DD/YYYY")
                        }`}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>Field Contact</label>
                    <input
                      readOnly
                      disabled
                      value={`${isEmpty(detail?.field_contact?.fullname)
                        ? ""
                        : detail?.field_contact?.fullname
                        }`}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>
                      Sheet #/Construction Doc #

                    </label>
                    <input
                      required
                      type="text"
                      disabled
                      readOnly
                      value={detail?.construction_docs}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>
                      Revit Version

                    </label>
                    <input
                      required
                      type="text"
                      disabled
                      readOnly
                      value={detail?.revit_version}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="shared-input">
                    <label>Notes</label>
                    <br />
                    <div className="d-flex">
                      <input
                        value={isEmpty(detail?.notes) ? "" : detail?.notes}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                {(detail?.status !== "accepted" &&
                  detail?.status !== "submitted") ||
                  isEmpty(detail?.accepted_by) ? (
                  <div className="col-sm-12" style={{ marginTop: "40px" }}>
                    <div className="shared-input d-flex ">
                      {!isLoading && (
                        <button
                          className="ml-auto btn btn-primary"
                          disabled={
                            isLoadingAccept || isLoading || isEmpty(detail)
                          }
                        >
                          {" "}
                          {!isLoadingAccept ? "Accept" : "Loading..."}
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </form>
            </div>
            <div className="col-sm-6">
              {(detail?.status === "accepted" ||
                detail?.status === "rejected") &&
                !isEmpty(detail?.accepted_by) && (
                  <>
                    <div className="col-sm-12">
                      <div className="shared-input">
                        <label>Accepted By</label>
                        <input
                          readOnly
                          disabled
                          value={`${userData?.user?.first_name} ${userData?.user?.last_name}`}
                        />
                      </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="col-sm-12">
                        <div className="shared-input">
                          <label>
                            Bim 360 Link<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            required
                            type="text"
                            placeholder="Enter Here"
                            onChange={(e) =>
                              setFieldValue("construction_link", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="shared-input">
                          <label>Additional Notes</label>
                          <br />
                          <div className="d-flex">
                            <textarea
                              placeholder="Enter Here"
                              className="col-sm-12"
                              onChange={(e) =>
                                setFieldValue(
                                  "additional_notes",
                                  e.target.value
                                )
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="shared-input">
                          <label>
                            Hours to Complete Task
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            required
                            placeholder="Enter Here"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            step="0.01"
                            type="number"
                            onChange={(e) =>
                              setFieldValue(
                                "hours_to_complete",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">

                        <div className="shared-input">
                          <label>Additional Documents</label>
                          {!isUploading ? (
                            <input
                              // hidden
                              type="file"
                              className="btn-attach"
                              onChange={handleAttachFile}
                            />
                          ) : (
                            <div
                              style={{ display: "flex" }}
                            >
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              <p style={{ marginLeft: "8px" }}>
                                Uploading...
                              </p>

                            </div>
                          )}
                          {
                            !isEmpty(arrFiles) && (
                              <div style={{ width: '100%', display: 'flex', marginTop: '8px', gap: '5px', padding: '5px', background: '#e5e1e1', borderRadius: '10px' }}>

                                <div className="col-sm-10 mt-auto mb-auto">
                                  <span>uploaded: </span>
                                  <a style={{
                                    width: '100%',
                                  }} href={arrFiles} alt={`Documents with link ${arrFiles}`}>
                                    {arrFiles}
                                  </a>
                                </div>
                                <button onClick={() => handleCancelDocuments()} className="col-sm-2 btn text-danger font-weight-bold">Delete</button>

                              </div>
                            )
                          }
                        </div>

                      </div>
                      {detail?.status !== "submitted" && (
                        <div
                          className="col-sm-12 d-flex"
                          style={{ marginTop: "50px" }}
                        >
                          <div className="shared-input d-flex ml-auto">
                            {detail?.status === "accepted" ||
                              detail?.status === "submitted" ? (
                              <>
                                {!isLoading && (
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    disabled={isUploading}
                                    onClick={() => setShowModalUnaccept(true)}

                                  >
                                    {" "}
                                    {!isLoadingUnaccept
                                      ? "Unaccept"
                                      : "Loading..."}
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            <button
                              disabled={
                                (isLoadingCreating || isSubmitted || isUploading) ? true : false
                              }
                              type="submit"
                              className="ml-2 btn btn-primary"
                            >
                              {!isLoadingCreating ? "Submit" : "Loading..."}
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </>
                )}
              {!isLoading &&
                !isNull(detail?.submitted_task?.fk_task.created_by) &&
                detail?.status !== "rejected" && (
                  <>
                    <div className="col-sm-12">
                      <div className="shared-input">
                        <label>Accepted By</label>
                        <input
                          readOnly
                          disabled
                          value={`${userData?.user?.first_name} ${userData?.user?.last_name}`}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="shared-input">
                        <label>
                          Bim 360 Link<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          disabled
                          defaultValue={
                            detail?.submitted_task?.construction_link
                          }
                          type="text"
                          placeholder="Enter Here"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="shared-input">
                        <label>Additional Notes</label>
                        <br />
                        <input
                          disabled
                          defaultValue={
                            detail?.submitted_task?.additional_notes
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="shared-input">
                        <label>
                          Hours to Complete Task
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          pattern="[0-9]+([\.,][0-9]+)?"
                          step="0.01"
                          type="number"
                          disabled
                          defaultValue={
                            detail?.submitted_task?.hours_to_complete
                          }
                        />
                      </div>
                    </div>

                  </>
                )}
            </div>
          </div>
        </div>
        <ModalUnaccept
          setShowModalUnaccept={setShowModalUnaccept}
          handleUnacceptTask={handleUnacceptTask}
          showModalUnaccept={showModalUnaccept}
          isLoadingUnaccept={isLoadingUnaccept}
          setNotes={setNotes}
        />
      </Page>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userData: state.userReducer,
  };
};

export default withRouter(connect(mapStateToProps)(TaskAccept));
