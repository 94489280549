export function ellipsisMiddle(str) {
  if (str.length > 35) {
    return str.substr(0, 15) + "..." + str.substr(str.length - 10, str.length);
  }
  return str;
}

export function ellipsisString(str, num) {
  if (str.length > num) {
    return str.substr(0, num) + "...";
  }
  return str;
}

export function sanitizeObj(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
}

export function removeUnderscoreEachKeyObject(obj) {
  const result = {};
  Object.keys(obj).forEach(function (key) {
    result[key.replace(/_/g, ' ')] = obj[key];
  });

  return result;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function checkingPermissions(arrPermissions, code) {
  // Checkin codename permission if exist on permissions will return boolean
  return arrPermissions.some(e => e.codename === code.toLowerCase())
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function formatMoney(string) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  if (string === null) {
    return null;
  } else {
    return formatter.format(string);
  }
}

export function calculateBusinessDays(startDate, endDate){
  // Validate input
  if (endDate < startDate)
      return 0;
  
  // Calculate days between dates
  var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0,0,0,1);  // Start just after midnight
  endDate.setHours(23,59,59,999);  // End just before midnight
  var diff = endDate - startDate;  // Milliseconds between datetime objects    
  var days = Math.ceil(diff / millisecondsPerDay);
  
  // Subtract two weekend days for every week in between
  var weeks = Math.floor(days / 7);
  days = days - (weeks * 2);
  
  // Handle special cases
  var startDay = startDate.getDay();
  var endDay = endDate.getDay();
  
  // Remove weekend not previously removed.   
  if (startDay - endDay > 1)         
      days = days - 2;      
  
  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay == 0 && endDay != 6) {
      days = days - 1;  
  }
  
  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay == 6 && startDay != 0) {
      days = days - 1;
  }
  
  return days;
  }

  export const generateMinDateToCreateMaterial = () => {
    let minDeliveryDate = new Date();
    // minDeliveryDate.setDate(minDeliveryDate.getDate() - 1);
    // minDeliveryDate = new Date(minDeliveryDate.getFullYear(), minDeliveryDate.getMonth() + 1, 1)
  
    if (minDeliveryDate.getDay() === 0) {
      minDeliveryDate.setDate(minDeliveryDate.getDate() + 1);
    } else if (minDeliveryDate.getDay() === 6) {
      minDeliveryDate.setDate(minDeliveryDate.getDate() + 2);
    }

    if (minDeliveryDate.getDay() === 4 || minDeliveryDate.getDay() === 5) {
      minDeliveryDate.setDate(minDeliveryDate.getDate() + 5);
    } else {
      minDeliveryDate.setDate(minDeliveryDate.getDate() + 3);
    }

    return minDeliveryDate;
  }

  export const generateWeekendDaysOnly = () => {
    let d = new Date();
    // d = new Date(d.getFullYear(), d.getMonth() + 1, 1)
    const months = [1,2,3,4,5,6,7,8,9,10,11,12]
    let sat = new Array();   // Array for inserting Saturdays
    let sun = new Array();   // Array for inserting Sundays

    for(let j=0;j<months.length;j++) {
      d = new Date(d.getFullYear(), d.getMonth() + j, 1)
      let getTot = daysInMonth(d.getMonth() + j,d.getFullYear()); //Get total days in a month

      for(let i=1;i<=getTot;i++){    //looping through days in month
          let currentMonth = new Date(d.getFullYear(),d.getMonth(),i)
          let secondMonth = new Date(d.getFullYear(),d.getMonth() + 1,i)
          let thirdMonth = new Date(d.getFullYear(),d.getMonth() + 2,i)
          if(currentMonth.getDay()==0) {   //if Sunday
              sun.push(new Date(`${d.getFullYear()}/${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1 }/${i < 10 ? `0${i}` : i}`));
          } 
          if(secondMonth.getDay()==0) {  
              sun.push(new Date(`${d.getFullYear()}/${d.getMonth() + 2 < 10 ? `0${d.getMonth() + 2}` : d.getMonth() + 2 }/${i < 10 ? `0${i}` : i}`));
          } 
          if(thirdMonth.getDay()==0) {  
              sun.push(new Date(`${d.getFullYear()}/${d.getMonth() + 3 < 10 ? `0${d.getMonth() + 3}` : d.getMonth() + 3 }/${i < 10 ? `0${i}` : i}`));
          } 
          
          if(currentMonth.getDay()==6) {   //if Saturday
            sat.push(new Date(`${d.getFullYear()}/${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1 }/${i < 10 ? `0${i}` : i}`));
          }
          if(secondMonth.getDay()==6) { 
            sat.push(new Date(`${d.getFullYear()}/${d.getMonth() + 2 < 10 ? `0${d.getMonth() + 2}` : d.getMonth() + 2 }/${i < 10 ? `0${i}` : i}`));
          }
          if(thirdMonth.getDay()==6) {   
            sat.push(new Date(`${d.getFullYear()}/${d.getMonth() + 3 < 10 ? `0${d.getMonth() + 3}` : d.getMonth() + 3 }/${i < 10 ? `0${i}` : i}`));
          }

        }
      }

        function daysInMonth(month,year) {
          return new Date(year, month, 0).getDate();
        }

        let weekend = sat.concat(sun);

        return weekend;
    }


export function encodeToSymbolic(str) {
  str = (str + '').toString();

  // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
  return str
    .replace('%21', '!')
    .replace('%28', '(')
    .replace('%29', ')')
    .replace('%2A', '*')
    .replace('%26', '&')
    .replace('%20', ' ')
}
