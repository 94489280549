import React, { useEffect, useState } from "react";
import { Page } from "../../shared";
import Select from "react-select";
import { Col, Container, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { getVendorList, postVendorManager, getMultiplierCategory } from "../../services";
import { get, isEmpty, isNull } from "lodash";
import { toast } from "react-toastify";
export default function VendorManager(){
    const [stateOptionsVendor, setStateOptionVendor] = useState([]);
    const [stateOptionsMultiplierCategory, setStateOptionsMultiplierCategory] = useState([]);
    const [isLoadingCreating, setIsLoadingCreating] = useState(false);
    const [stateVendor, setStateVendor] = useState([]);
    const [defaultValuesVendor, setDefaultValuesVendor] = useState([]);
    const [formReps, setFormReps] = useState([
        [{
            'label': 'Rep Name #',
            'id': 'rep_name_1',
            'type': 'text',
        },
        {
            'label': 'Phone #',
            'id': 'phone_1',
            'type': 'number',
        },
        {
            'label': 'Email #',
            'id': 'email_1',
            'type': 'email',
        }],
    ]);

    const [formMultipliers, setFormMultipliers] = useState([
        [{
            'label': 'Multiplier Category',
            'id': `multiplier_category_1`,
        },
        {
            'label': 'Multiplier',
            'id': `multiplier_1`,
        }],
    ]);

    const [initialValues, setInitialValues] = useState({
        name: "",
        address: "",
        web_address: "",
        number_of_record_provided: "",
        number_of_record_catalog: "",
        api_url : "",
        api_token : "",
        file_type : ""
    })

    useEffect(() => {
        if(isEmpty(stateVendor)) return ;
        const arrDefault = {
            name: stateVendor.split(',')[1] == "null" ? "" : stateVendor.split(',')[1],
            address: stateVendor.split(',')[2] == "null" ? "" : stateVendor.split(',')[2],
            url: stateVendor.split(',')[3] == "null" ? "" : stateVendor.split(',')[3],
        }
        console.log(arrDefault);
        setDefaultValuesVendor(arrDefault);
    }, [stateVendor])

    const addMoreMultiplier = () => {
        setFormMultipliers([...formMultipliers, [{
            'label': 'Multiplier Category',
            'id': `multiplier_category_${formMultipliers.length + 1}`,
        },
        {
            'label': 'Multiplier',
            'id': `multiplier_${formMultipliers.length + 1}`,
        }]]);

    }
    
    const addMoreReps = () => {
        setFormReps([...formReps, [{
            'label': 'Rep Name #',
            'id': `rep_name_${formReps.length + 1}`,
            'type': 'text',
        },
        {
            'label': 'Phone #',
            'id': `phone_${formReps.length + 1}`,
            'type': 'number',
        },
        {
            'label': 'Email #',
            'id': `email_${formReps.length + 1}`,
            'type': 'email',
        }]]);

    }

    const getVendorListData = async (page, page_size) => {
        const res = await getVendorList(page, page_size);
        setStateOptionVendor(
            res.results.map((value) => ({
                label: value?.name,
                value: `${value?.id},${value?.name},${value?.address},${value?.url}`
            }))
        );
    }

    const getMultiplierCategoryData = async (page, page_size) => {
        const res = await getMultiplierCategory(page, page_size);
        setStateOptionVendor(
            res.results.map((value) => ({
                label: `${value?.name}`,
                value: `${value?.id}`
            }))
        );
    }

    const handleSubmitVendorManager = async () => {
        let reps = [];
        reps.push(
            formReps.map((val, key) => {
                return [{
                    name: document.getElementById(`rep_name_${key + 1}`).value,   
                    phone: document.getElementById(`phone_${key + 1}`).value,   
                    email: document.getElementById(`email_${key + 1}`).value,   
                }];
            })
        )
        console.log(reps)
        // try {
            
        //     setIsLoadingCreating(true);
            
        //     const payload = {
        //       ...values,
        //     };
        //     let res;
        //     res = await postVendorManager(payload);
        //     if (res) {
        //       toast.success("Vendor Manager successfully created", {
        //         position: "bottom-right",
        //         autoClose: 2000,
        //       });
        //       setInterval(() => {
        //         window.location.replace("/total-station-scheduler/app");
        //       }, 2000);
        //     }
        //   } catch (error) {
        //     toast.error(
        //       "Unknown error occured. Unable to create Vendor Manager",
        //       {
        //         position: "bottom-right",
        //         autoClose: 2000,
        //       }
        //     );
        //     console.error(error);
        //   } finally {
        //     setIsLoadingCreating(false);
        //   }
    }

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmitVendorManager,
        enableReinitialize: true
    })
    const { values, setFieldValue, handleChange, handleSubmit } = formik;

    useEffect(() => {
        console.log(formReps);
    }, [formReps])
    
    useEffect(() => {
        if(isEmpty(stateOptionsVendor)) {
            const queryParams = {
                'page': '1',
                'page_size': '1000'
            };
            getVendorListData(queryParams?.page, queryParams?.page_size);
        };
    }, [stateOptionsVendor]) 

    useEffect(() => {
        if(isEmpty(stateOptionsMultiplierCategory)) {
            const queryParams = {
                'page': '1',
                'page_size': '1000'
            };
            getMultiplierCategoryData(queryParams?.page, queryParams?.page_size);
        };
    }, [stateOptionsMultiplierCategory]) 

    return (<>
        <div style={{padding: '12px', background: 'white'}}>
            <Page title={'Vendor Manager'} >
                <Container >
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={6} md={12} xs={12} style={{marginBottom: '8px'}}>
                                <label><b>Select Vendor :</b></label>
                                <Select
                                    onChange={(e) => setStateVendor(e.value)}
                                    options={stateOptionsVendor}
                                    type="text"
                                    required
                                    placeholder="Enter Here"
                                />
                            </Col>
                            <Col lg={6} md={12} xs={12}>
                                <div className="form-group">
                                    <label for="staticEmail"><b>Name:</b></label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        onChange={(e) => setFieldValue('name', e.target.value)} 
                                        defaultValue={defaultValuesVendor?.name}
                                        required />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12} xs={12}>
                                <div className="form-group">
                                    <label for="staticEmail"><b>Address:</b></label>
                                    <input type="text" className="form-control" onChange={(e) => setFieldValue('address', e.target.value)} defaultValue={defaultValuesVendor?.address} required  />
                                </div>
                            </Col>
                            <Col lg={6} md={12} xs={12}>
                                <div className="form-group">
                                    <label for="staticEmail"><b>Web Address:</b></label>
                                    <input type="text" className="form-control" onChange={(e) => setFieldValue('web_address', e.target.value)} defaultValue={defaultValuesVendor?.url} required  />
                                </div>
                            </Col>
                        </Row>
                    
                        <br/>
                        <Row>
                            {formReps.map((val, key) => {
                                return (
                                    <Col xs={6}>
                                        {val?.map((reps, key) => {
                                            return (
                                                <div className="form-group">
                                                    <label for="staticEmail"><b>{reps?.label} :</b></label>
                                                    <input type={reps.type} id={reps.id} className="form-control" required />
                                                </div>
                                            )
                                        })}
                                    </Col>
                                )
                            })}
                        
                            <Col xs={6} className="d-flex">
                                <button type="button" onClick={addMoreReps} className="btn btn-transparent text-primary mr-auto mt-auto mb-auto">
                                <i className="ti ti-plus"></i> Add More Reps
                                </button>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={6} md={12} xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <h4>Catalog</h4>
                                        <hr/>
                                    </Col>
                            
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div className="form-group">
                                            <label><b>Number Of Records Provided:</b></label>
                                            <input type="number" className="form-control" onChange={(e) => setFieldValue('number_of_record_provided', e.target.value)} required  />
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="form-group">
                                            <label><b>Number Of Records in Catalog:</b></label>
                                            <input type="number" className="form-control" onChange={(e) => setFieldValue('number_of_record_catalog', e.target.value)} required  />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} md={12} xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <h4>Sync Process</h4>
                                        <hr/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div className="form-group">
                                            <label><b>File Type:</b></label>
                                            <input type="text" className="form-control" onChange={(e) => setFieldValue('file_type', e.target.value)} required />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} md={12} xs={12}>
                                        <div className="form-group">
                                            <label><b>API Url:</b></label>
                                            <input type="text" className="form-control" onChange={(e) => setFieldValue('api_url', e.target.value)} />
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} xs={12}>
                                        <div className="form-group">
                                            <label><b>API Token:</b></label>
                                            <input type="text" className="form-control" onChange={(e) => setFieldValue('api_token', e.target.value)} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={12} md={12} xs={12}>
                                <Row>
                                    {formMultipliers.map((val, key) => {
                                        return (
                                            <Col xs={6}>
                                                {val?.map((form, key) => {
                                                    if(form?.label === 'Multiplier Category'){
                                                        return (
                                                            <div className="form-group">
                                                                <label for="staticEmail"><b>{form?.label} :</b></label>
                                                                <Select
                                                                    id={form.id}
                                                                    options={stateOptionsMultiplierCategory}
                                                                    required
                                                                    placeholder="Select Multiplier Category"
                                                                />
                                                            </div>
                                                        );
                                                    }else{
                                                        return (
                                                            <div className="form-group">
                                                                <label for="staticEmail"><b>{form?.label} :</b></label>
                                                                <input type="number" id={form.id} className="form-control" required />
                                                            </div>
                                                        );
                                                    }
                                                    
                                                })}
                                            </Col>
                                        )
                                    })}
                            
                                    <Col xs={6} className="d-flex">
                                        <button type="button" onClick={addMoreMultiplier} className="btn btn-transparent text-primary mr-auto mt-auto mb-auto">
                                        <i className="ti ti-plus"></i> Add More Multipliers
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        
                        </Row>
                        <br/>
                        <Row>
                            <Col className="d-flex">
                                <button disabled className="ml-auto btn btn-primary">Submit</button>
                            </Col>
                            
                        </Row>
                    </form>
                </Container>
            </Page>
        </div>
    </>);
}