import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

function PopupDialog(props) {
  const {
    modalData,
    onHide,
    hideText,
    onSubmitPrimary,
    submitTextPrimary,
    onSubmitSecondary,
    submitTextSecondary,
    closeButton = true,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitPrimary = async () => {
    try {
      setIsSubmitting(true);
      onSubmitPrimary();
      onHide();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitSecondary = async () => {
    try {
      setIsSubmitting(true);
      onSubmitSecondary();
      onHide();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        show={modalData?.isOpen}
        onHide={onHide}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>{modalData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{modalData?.bodyContent}</p>
        </Modal.Body>
        <Modal.Footer>
          {hideText && (
            <Button size="sm" onClick={onHide} variant="secondary">
              {hideText}
            </Button>
          )}
          {submitTextSecondary && (
            <Button
              disabled={isSubmitting}
              size="sm"
              onClick={handleSubmitSecondary}
              variant="secondary"
            >
              {submitTextSecondary}
            </Button>
          )}
          {submitTextPrimary && (
            <Button
              disabled={isSubmitting}
              size="sm"
              onClick={handleSubmitPrimary}
              variant="primary"
            >
              {submitTextPrimary}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default withRouter(PopupDialog);
