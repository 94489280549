import React from "react";
import { Spinner } from "react-bootstrap";
import { useSortBy, useTable, usePagination } from "react-table";
import Select from "react-select";
import { useWindowSize } from "./../helper/hooks";

export const Table = (props) => {
  const { tableData, tableHeader, loading } = props;

  const screenSize = useWindowSize();
  const screenMobile = screenSize?.width <= 400 ? true : false;

  const data = React.useMemo(() => tableData ?? [], [tableData]);
  const columns = React.useMemo(() => tableHeader ?? [], [tableHeader]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data }, useSortBy, usePagination);
  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <table {...getTableProps()} className="shared-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(
                  (column) => (
                    console.log({ column }),
                    (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <span>
                          {/* {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i
                            className="ti-arrow-down"
                            style={{ fontSize: "12px", marginRight: "3px" }}
                          />
                        ) : (
                          <i
                            className="ti-arrow-up"
                            style={{ fontSize: "12px", marginRight: "3px" }}
                          />
                        )
                      ) : (
                        <i
                          className="ti-exchange-vertical"
                          style={{ fontSize: "12px", marginRight: "3px" }}
                        />
                      )} */}
                          <i
                            className="ti-exchange-vertical"
                            style={{
                              fontSize: "12px",
                              marginRight: "6px",
                              color: "#2c3e50",
                            }}
                          />
                        </span>
                        {column.render("Header")}
                      </th>
                    )
                  )
                )}
              </tr>
            ))}
          </thead>
          {!loading && (
            <tbody {...getTableBodyProps()}>
              {/* {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })} */}
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
          {loading && (
            <tbody>
              <tr>
                <td
                  colSpan={tableHeader.length}
                  style={{ height: "100px", textAlign: "center" }}
                >
                  <Spinner variant="primary" animation="border" />
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      <div
        className="shared-filter_list"
        style={{
          display: screenMobile ? "block" : "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "6px 0",
          }}
        >
          <p>
            {pageSize * pageIndex + 1}-
            {canNextPage ? pageSize * (pageIndex + 1) : tableData?.length} of{" "}
            {tableData?.length}
          </p>
          <span style={{ margin: "0 8px" }}>|</span>
          <p>Showing</p>
          <Select
            isSearchable={false}
            value={[pageSize].map((val) => ({ label: val, value: val }))}
            options={[
              { label: "10", value: 10 },
              { label: "50", value: 50 },
              { label: "100", value: 100 },
            ]}
            classNamePrefix="select-filter"
            onChange={(selected) => {
              setPageSize(selected.value);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div
          className="list-pagination"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "6px 0",
          }}
        >
          <i
            className={`ti-control-skip-backward ${
              !canPreviousPage ? "btn-control-disabled" : ""
            }`}
            onClick={() => gotoPage(0)}
          />
          <i
            className={`ti-control-backward ${
              !canPreviousPage ? "btn-control-disabled" : ""
            }`}
            onClick={() => previousPage()}
          />
          <p className="pagination-label">
            Page {pageIndex + 1} of {pageOptions?.length}
          </p>
          <i
            className={`ti-control-forward ${
              !canNextPage ? "btn-control-disabled" : ""
            }`}
            onClick={() => nextPage()}
          />
          <i
            className={`ti-control-skip-forward ${
              !canNextPage ? "btn-control-disabled" : ""
            }`}
            onClick={() => gotoPage(pageCount - 1)}
          />
        </div>
      </div>
    </>
  );
};
