import S3 from "aws-s3";

const config = {
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  // dirName: "media" /* optional */,
  region: "us-west-2",
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
  // s3Url: "https:/your-custom-s3-url.com/" /* optional */,
};

const s3Client = new S3(config);

export { s3Client };
