import React from "react";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";

export const Input = (props) => {
  const { label, rows } = props;

  return (
    <div className="shared-input">
      {label && <p>{label}</p>}
      {!rows ? (
        <input {...props} />
      ) : (
        <textarea rows={rows} {...props}>
          {props.value}
        </textarea>
      )}
    </div>
  );
};

export const InputNumber = (props) => {
  const { label } = props;
  return (
    <div className="shared-input">
      {label && <p>{label}</p>}
      <NumberFormat {...props} />
    </div>
  );
};

export const InputWrapper = (props) => {
  const { label, required, children } = props;

  return (
    <div className="shared-input-wrapper">
      <Row>
        <Col md={4} style={{ display: "flex", alignItems: "center" }}>
          <p style={{ fontWeight: "500" }}>
            {label}
            {required ? <span style={{ color: "red" }}>*</span> : ""}
          </p>
        </Col>
        <Col md={8}>{children}</Col>
      </Row>
    </div>
  );
};
