import React from "react";
import Modal from "react-bootstrap/Modal";
export default function ModalUnaccept(props) {
    const {
        setShowModalUnaccept,
        handleUnacceptTask,
        showModalUnaccept,
        isLoadingUnaccept,
        setNotes,
    } = props
    return (
        <Modal
            show={showModalUnaccept}
            onHide={() => setShowModalUnaccept(false)}
        >
            <Modal.Header closeButton>
                {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <form onSubmit={handleUnacceptTask}>
                        <div className="shared-input">
                            <div>
                                <div>
                                    <label>Please Enter notes for unaccept the task:</label>
                                    <textarea
                                        onChange={(e) => setNotes(e.target.value)}
                                        required
                                        className="form-control"
                                        minLength={1}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        {/* <div className="shared-input">
                <label>Additional Documents</label>
                {!isUploading ? (
                  <input
                    // hidden
                    type="file"
                    className="btn-attach"
                    onChange={handleAttachFile}
                  />
                ) : (
                  <div
                    style={{ display: "flex" }}
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <p style={{ marginLeft: "8px" }}>
                      Uploading...
                    </p>

                  </div>
                )}
                {
                  !isEmpty(arrFiles) && (
                    <div style={{ width: '100%', display: 'flex', marginTop: '5px', gap: '5px' }}>
                      <button onClick={() => handleCancelDocuments()} className="btn btn-transparent text-danger "><i className="ti ti-close font-weight-bold"></i> </button>
                      <div>
                        <span>uploaded: </span>
                        <a style={{
                          width: '100%',
                        }} href={arrFiles} alt={`Documents with link ${arrFiles}`}>
                          {arrFiles}
                        </a>
                      </div>

                    </div>
                  )
                }
              </div> */}
                        <br />
                        <div className="form-group d-flex">
                            <button
                                disabled={isLoadingUnaccept ? true : false}
                                type="submit"
                                className="ml-auto btn btn-danger"
                            >
                                {isLoadingUnaccept
                                    ? `Loading...`
                                    : `Confirm Task is Unaccepted`}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )

}